import React from 'react';
import WebApp from '@twa-dev/sdk';
import Lottie from 'lottie-react';
import Popup from './Popup';
import starAnimation from '../../assets/js/star.json';

const ChannelSubscriptionChecker = ({ onClose }) => {
  const handleSubscribe = () => {
    WebApp.openTelegramLink('https://t.me/ainetworki');
    WebApp.close();
  };

  return (
    <Popup onClose={onClose}>
      <div className="alert-popup">
        <div className="lottie-container-min">
          <Lottie animationData={starAnimation} loop={true} />
        </div>
        <h3>Подпишитесь на нас</h3>
        <p>
          Чтобы использовать приложение - подпишитесь на наш канал в Telegram, чтобы не пропустить ничего нового.
          После подписки Вам станет доступен весь функционал бота.
        </p>
        <button className="go-to-button" onClick={handleSubscribe}>
          Подписаться
        </button>
      </div>
    </Popup>
  );
};

export default ChannelSubscriptionChecker;