import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import Header from './Header';
import AnimatedRoutes from './AnimatedRoutes';
import { ProfileProvider, useProfile } from './fetch/apiProvider';
import { LoaderProvider, useLoader } from './other/LoaderContext';
import { ThemeProvider } from './other/ThemeProvider';
import Loader from './other/Loader';
import Maintenance from './Maintenance';

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const appContainerRef = useRef(null);
  const { isLoading: isProfileLoading, profile, tariffs, fetchData } = useProfile();
  const { isLoading: isPageLoading, setIsLoading } = useLoader();
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const navigateRef = useRef(navigate);

  useEffect(() => {
    if (!isProfileLoading && profile && profile.blocked) {
      navigate('/app/banned');
    }
  }, [isProfileLoading, profile, navigate]);

  useEffect(() => {
    if (appContainerRef.current) {
      appContainerRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]);

  const initializeApp = useCallback(async () => {
    if (!profile || !tariffs) {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    }
    setIsInitialLoadComplete(true);
    
    WebApp.setHeaderColor('secondary_bg_color');
    WebApp.expand();
  }, [fetchData, profile, tariffs, setIsLoading]);

  useEffect(() => {
    initializeApp();
  }, [initializeApp]);

  useEffect(() => {
    if (isInitialLoadComplete) {
      const rootLoader = document.getElementById('root-loader');
      if (rootLoader) {
        rootLoader.remove();
      }
    }
  }, [isInitialLoadComplete]);

  const handleRouteChange = useCallback(() => {
    if (isInitialLoadComplete) {
      setIsLoading(true);
      setIsContentVisible(false);
      setIsLoading(false);
      setIsContentVisible(true);
    }
  }, [setIsLoading, isInitialLoadComplete]);

  useEffect(() => {
    if (isInitialLoadComplete) {
      handleRouteChange();
    }

    const originalNavigate = navigateRef.current;
    navigateRef.current = (...args) => {
      if (isInitialLoadComplete) {
        handleRouteChange();
      }
      originalNavigate(...args);
    };

    return () => {
      navigateRef.current = originalNavigate;
    };
  }, [location, handleRouteChange, isInitialLoadComplete]);

  if (!isInitialLoadComplete || isProfileLoading) {
    return <Loader />;
  }

  if (!profile || !tariffs) {
    return <Maintenance />;
  }

  return (
    <div className="app-container" ref={appContainerRef}>
      <Header />
      {isPageLoading && <Loader />}
      <div className={`app-content ${isContentVisible ? 'visible' : 'hidden'}`}>
        <AnimatedRoutes />
      </div>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider>
      <LoaderProvider>
        <ProfileProvider>
          <AppContent />
        </ProfileProvider>
      </LoaderProvider>
    </ThemeProvider>
  );
}

export default App;