import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';

import GenerationForm from './GenerationForm';
import AnimatedPage from '../AnimatedPage';
import Help from '../other/Help';
import DocsLink from '../docs/DocsLink';

const SimpleGeneration = () => {
  const [isInstrumental, setIsInstrumental] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const savedIsInstrumental = localStorage.getItem('simple_isInstrumental') === 'true';
    setIsInstrumental(savedIsInstrumental);
  }, []);

  useEffect(() => {
    localStorage.setItem('simple_isInstrumental', isInstrumental.toString());
  }, [isInstrumental]);

  useEffect(() => {
    const handleBackButton = () => {
      navigate('/app');
      WebApp.BackButton.hide();
    };

    WebApp.BackButton.show();
    WebApp.BackButton.onClick(handleBackButton);

    return () => {
      WebApp.BackButton.offClick(handleBackButton);
    };
  }, [navigate]);

  const fields = [
    {
      id: 'description',
      label: 'Описание трека',
      maxLength: 200,
      containerClass: 'simple-container',
      placeholder: 'Опишите что Вы хотите услышать. Например: Трек про любовь в жанре emotional synthwave',
      tooltip: (
        <>
          Пожалуйста, отправьте описание трека, что Вы хотите услышать. ИИ сам напишет текст (если Вы не выберете, что трек будет инструментальным) и пришлёт 2 варианта трека.<br/><br/>
          <DocsLink href="https://music.bloom-digital.ru/how-use-suno-bot/">Как использовать бота для создания треков?</DocsLink>
        </>
      ),
      rows: 5
    }
  ];

  const handleSubmit = useCallback(async (formData) => {
    try {
      const response = await fetch('/api/start_simple_generation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: WebApp.initDataUnsafe.user.id,
          description: formData.description,
          make_instrumental: isInstrumental
        }),
      });
      
      const data = await response.json();
      
      if (data.status !== 'success') {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      WebApp.showAlert(error.message);
    }
  }, [isInstrumental]);

  const renderHeader = (field, index) => (
    <>
      <div className="label-container">
        <label htmlFor={field.id}>{field.label}</label>
        <Help>{field.tooltip}</Help>
      </div>
      <div className="instrumental-toggle">
        <label className="switch">
          <input
            type="checkbox"
            checked={!isInstrumental}
            onChange={() => {
              const newValue = !isInstrumental;
              setIsInstrumental(newValue);
              localStorage.setItem('simple_isInstrumental', newValue.toString());
            }}
          />
          <span className="slider round"></span>
        </label>
        <span>С вокалом?</span>
      </div>
    </>
  );

  return (
    <AnimatedPage>
        <GenerationForm
          fields={fields}
          onSubmit={handleSubmit}
          renderHeader={renderHeader}
          extraData={{ isInstrumental }}
          onExtraDataChange={(data) => {
            if (data === null) {
              setIsInstrumental(false);
              localStorage.setItem('simple_isInstrumental', 'false');
            }
          }}
          showResetButton={true}
          resetButtonLocation="inside"
          storagePrefix="simple_"
          title="ПРОСТАЯ ГЕНЕРАЦИЯ"
        />
    </AnimatedPage>
  );
};

export default SimpleGeneration;