import React from 'react';
import AnimatedPage from './AnimatedPage';
import Lottie from 'lottie-react';
import poopAnimation from '../assets/js/poop.json';

const Banned = () => {
  return (
    <AnimatedPage>
      <div className="banned-container">
        <div className="lottie-container-min">
          <Lottie animationData={poopAnimation} loop={true} />
        </div>
        <h3>Вы забанены навсегда</h3>
        <p>
          Всего хорошего!
        </p>
      </div>
    </AnimatedPage>
  );
};

export default Banned;