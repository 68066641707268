import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import { useServiceRequest } from './useServiceRequest';
import ImportantNotice from '../other/ImportantNotice';
import AnimatedPage from '../AnimatedPage';

const serviceConfig = {
  new_voice: {
    title: "Заявка на добавление голоса",
    description: "<p>Мы добавляем модели голосов, которые есть в общем доступе. Если у нас не получится их найти - голос добавлен не будет.</p>",
    placeholder: "Напиши, голос какого исполнителя\\персонажа Вы хотели бы Видеть в боте...",
    apiEndpoint: "/api/new_voice",
    rows: 5,
    importantNotice: "Добавляем только известных персонажей. Чтобы добавить лично ВАШ голос в базу данных - нужно сначала обучить модель.</p><p>Посмотрите раздел <a href='/app/services/'>УСЛУГИ</a> и выберите пункт обучения модели",
    url: "/app/services/new_voice"
  },
  neuro_cover: {
    title: "Заявка на нейрокавер",
    description: "<p>Запишем трек с помощью нейросетей, обработаем и изменим на выбранный Вами голос. Оставьте заявку и с Вами свяжутся в течении 15мин!</p>",
    placeholder: "Опишите, что хотели бы видеть в нейрокавере...",
    apiEndpoint: "/api/submit_service_request",
    rows: 7,
    importantNotice: "Чтобы исполнитель мог с Вами связаться - проверьте, установлен ли у Вас @логин в настройках Telegram",
    url: "/app/services/neuro_cover"
  },
  live_neuro_cover: {
    title: "Заявка на живой нейрокавер",
    description: "<p>'Ай, гитарист! (Андрей)' сам споет голосами легендартных певцов. Авторское исполнение для поклонников Андрея.</p>",
    placeholder: "Опишите, что хотели бы видеть в нейрокавере...",
    apiEndpoint: "/api/submit_service_request",
    rows: 6,
    importantNotice: "Чтобы исполнитель мог с Вами связаться - проверьте, установлен ли у Вас @логин в настройках Telegram",
    url: "/app/services/live_neuro_cover"
  },
  model_training: {
    title: "Заявка на обучение модели",
    description: "<p>Обучим для Вас голосовую модель. Сами подготовим и обработаем датасет, проведем все корректировки и вышлем Вам готовые файлы. При желании - голос можно будет добавить в бота</p>",
    placeholder: "Опишите ваш запрос здесь...",
    apiEndpoint: "/api/submit_service_request",
    rows: 8,
    importantNotice: "Чтобы исполнитель мог с Вами связаться - проверьте, установлен ли у Вас @логин в настройках Telegram",
    url: "/app/services/model_training"
  }
};

const AllServices = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const serviceType = location.pathname.split('/').pop();
  const config = serviceConfig[serviceType];

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => {
      if (serviceType === 'new_voice') {
        navigate('/app');
      } else {
        navigate('/app/services');
      }
      WebApp.BackButton.hide();
    });

    return () => {
      WebApp.BackButton.offClick();
      WebApp.BackButton.hide();
    };
  }, [navigate, serviceType]);

  const { description, isLoading, handleDescriptionChange } = useServiceRequest(serviceType, config.apiEndpoint);

  if (!config) {
    return <div>Service not found</div>;
  }

  return (
    <AnimatedPage>
      <div className="contact-us">
        <div className="menu-section-header">
          <h2>{config.title}</h2>
          <div className="menu-section-line"></div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: config.description }} />
        <div className="form-container">
          <textarea 
            value={description}
            onChange={handleDescriptionChange}
            placeholder={config.placeholder}
            rows={config.rows}
          />
          <div className="form-footer">
            <div className="char-count">
              {description.length} / 1000
            </div>
          </div>
        </div>
        <ImportantNotice>
          <div dangerouslySetInnerHTML={{ __html: config.importantNotice }} />
        </ImportantNotice>
        {isLoading && <div className="loader-container"><div className="loader"></div></div>}
      </div>
    </AnimatedPage>
  );
};

export default AllServices;