import React from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

const pageVariants = {
  initial: (direction) => ({
    opacity: 0,
    x: direction > 0 ? '100%' : '-100%',
  }),
  in: {
    opacity: 1,
    x: 0,
  },
  out: (direction) => ({
    opacity: 0,
    x: direction > 0 ? '-100%' : '100%',
  }),
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.2,
};

const getDepth = (pathname) => {
  return pathname.split('/').filter(Boolean).length;
};

const AnimatedPage = ({ children }) => {
  const location = useLocation();
  const [prevPathname, setPrevPathname] = React.useState(location.pathname);

  React.useEffect(() => {
    setPrevPathname((prev) => {
      return prev !== location.pathname ? prev : location.pathname;
    });
  }, [location.pathname]);

  const direction = React.useMemo(() => {
    const currentDepth = getDepth(location.pathname);
    const previousDepth = getDepth(prevPathname);

    if (currentDepth === previousDepth) {
      // Если глубина одинакова, анимируем справа налево
      return 1;
    } else if (currentDepth > previousDepth) {
      // Если переходим глубже, анимируем слева направо
      return 1;
    } else {
      // Если возвращаемся назад, анимируем справа налево
      return -1;
    }
  }, [location.pathname, prevPathname]);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      custom={direction}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedPage;