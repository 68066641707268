import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';

import { useLoader } from './other/LoaderContext';
import AnimatedPage from './AnimatedPage';

function getTelegramThemeColors() {
  const root = document.documentElement;
  const computedStyle = getComputedStyle(root);

  return {
    background: computedStyle.getPropertyValue('--tg-theme-secondary-bg-color').trim() || '#2c2c2c)',
    border: computedStyle.getPropertyValue('--tg-theme-hint-color').trim() || '#555555',
    control_primary: computedStyle.getPropertyValue('--tg-theme-button-color').trim() || '#047bff',
    control_primary_content: computedStyle.getPropertyValue('--tg-theme-button-text-color').trim() || '#ffffff',
    control_secondary: computedStyle.getPropertyValue('--tg-theme-secondary-bg-color').trim() || '#333333'
  };
}

function Payment() {
  const [error, setError] = useState(null);
  const { setIsLoading } = useLoader();
  const [isWidgetReady, setIsWidgetReady] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const checkoutRef = useRef(null);
  const initializeAttemptedRef = useRef(false);

  const goBack = useCallback(() => {
    navigate('/app/tariffs');
  }, [navigate]);

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(goBack);

    return () => {
      WebApp.BackButton.offClick(goBack);
      WebApp.BackButton.hide();
    };
  }, [goBack]);

  useEffect(() => {
    const handleGlobalError = (event) => {
      if (event.message === 'Script error.' && 
          event.filename.includes('tgtest.bloom-digital.ru/static/js/bundle.js')) {
        navigate('/app/thx-payment');
      }
    };

    window.addEventListener('error', handleGlobalError);

    return () => {
      window.removeEventListener('error', handleGlobalError);
    };
  }, [navigate]);

  const initializePayment = useCallback(async () => {
    if (initializeAttemptedRef.current) return;
    initializeAttemptedRef.current = true;

    try {
      setIsLoading(true);

      const script = document.createElement('script');
      script.src = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';
      script.async = true;
      document.body.appendChild(script);

      await new Promise((resolve) => {
        script.onload = resolve;
      });

      const queryParams = new URLSearchParams(location.search);
      const amount = queryParams.get('amount');
      const userId = queryParams.get('user_id');
      const tariff = queryParams.get('tariff');
      const action = queryParams.get('action');

      if (!amount || !userId || !tariff || !action) {
        throw new Error('Отсутствуют необходимые параметры.');
      }

      const response = await fetch('/api/create_payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: parseFloat(amount), user_id: parseInt(userId), tariff, action }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (!data.confirmation_token) {
        throw new Error('Не получен токен подтверждения от сервера');
      }

      const themeColors = getTelegramThemeColors();

      checkoutRef.current = new window.YooMoneyCheckoutWidget({
        confirmation_token: data.confirmation_token,
        return_url: window.location.origin + '/app/payment?status=success',
        customization: {
          colors: themeColors
        },
        error_callback: function(error) {
          setError('Произошла ошибка при оплате');
          setIsLoading(false);
        }
      });

      const paymentForm = document.getElementById('payment-form');
      if (!paymentForm) {
        throw new Error('Элемент payment-form не найден');
      }

      await checkoutRef.current.render('payment-form');
      
      setIsWidgetReady(true);
      setIsLoading(false);

      checkoutRef.current.on('success', () => {
        navigate('/app/thx-payment');
      });

      checkoutRef.current.on('fail', () => {
        setError('Оплата не удалась. Пожалуйста, попробуйте еще раз.');
      });

    } catch (error) {
      setError(`Ошибка: ${error.message}`);
      setIsLoading(false);
    }
  }, [location.search, navigate, setIsLoading]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('status') === 'success') {
      navigate('/app/thx-payment');
    } else {
      initializePayment();
    }
  }, [location.search, initializePayment, navigate]);

  if (error) {
    return (
      <div className="error" style={{ textAlign: 'center', padding: '20px' }}>
        <p>{error}</p>
        <button onClick={() => navigate('/app/tariffs')}>Вернуться к тарифам</button>
      </div>
    );
  }

  return (
    <AnimatedPage>
      <>
        <div className="menu-section-header">
          <h2>Оплата</h2>
          <div className="menu-section-line"></div>
        </div>
        <div id="payment-form" style={{ display: isWidgetReady ? 'block' : 'none' }}></div>
      </>
    </AnimatedPage>
  );
}

export default Payment;