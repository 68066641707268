import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const DocsLink = ({ href, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e) => {
    e.preventDefault();
    const backUrl = encodeURIComponent(location.pathname);
    navigate(`/app/browser?url=${encodeURIComponent(href)}&back=${backUrl}`);
  };

  return (
    <span 
      onClick={handleClick}
      style={{ color: 'var(--tg-theme-link-color, #007bff)', cursor: 'pointer'}}
    >
      {children}
    </span>
  );
};

export default DocsLink;