import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import AnimatedPage from './AnimatedPage';
import DocsLink from './docs/DocsLink';

const Support = () => {
  const navigate = useNavigate();

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => {
      navigate('/app');
      WebApp.BackButton.hide();
    });

    return () => {
      WebApp.BackButton.offClick();
      WebApp.BackButton.hide();
    };
  }, [navigate]);

  return (
    <AnimatedPage>
      <div className="support-container">
        <div className="menu-section-header">
          <h2>Поддержка</h2>
          <div className="menu-section-line"></div>
        </div>
        <div className="support-content">
          <p>Если у вас возникли вопросы или проблемы, пожалуйста, обратитесь в нашу поддержку:</p>
          <p><a href="https://t.me/sundio_support">@sundio_support</a></p>
          <p>Перед запросом, пожалуйста, изучите <DocsLink href="https://music.bloom-digital.ru/category/docs/">инструкции</DocsLink> и <DocsLink href="https://music.bloom-digital.ru/faq/">FAQ</DocsLink>. Скорее всего ответ на Ваш вопрос там</p>
          <p>🕗 Мы отвечаем с 10:00 до 19:00 в рабочие дни</p>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default Support;