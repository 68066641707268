import React from 'react';
import { useLoader } from './LoaderContext';

function Loader() {
  const { isLoading } = useLoader();

  if (!isLoading) return null;

  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
}

export default Loader;