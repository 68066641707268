import React, { useState, useEffect, useRef, useCallback } from 'react';
import WebApp from '@twa-dev/sdk';

import Help from '../other/Help';
import { useLoader } from '../other/LoaderContext';
import '../../App.css';

const GenerationForm = ({ 
  fields,
  onSubmit,
  children,
  renderHeader,
  extraData,
  onExtraDataChange,
  showResetButton = true,
  resetButtonLocation = 'outside',
  storagePrefix = '',
  title
}) => {
  const [formData, setFormData] = useState({});
  const [isExceeded, setIsExceeded] = useState({});
  const { setIsLoading } = useLoader();
  const [isGeneratingLyrics, setIsGeneratingLyrics] = useState(false);
  
  const textareaRefs = useRef({});
  const exceededTimeouts = useRef({});

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      try {
        const savedData = {};
        fields.forEach(field => {
          const savedValue = localStorage.getItem(`${storagePrefix}${field.id}`);
          savedData[field.id] = savedValue ? savedValue.slice(0, field.maxLength) : '';
        });
        setFormData(savedData);
      } catch (error) {
        console.error('Error loading initial data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, [fields, storagePrefix, setIsLoading]);

  const handleInputChange = (fieldId, maxLength) => (e) => {
    const newValue = e.target.value.slice(0, maxLength);
    setFormData(prev => {
      const updated = { ...prev, [fieldId]: newValue };
      localStorage.setItem(`${storagePrefix}${fieldId}`, newValue);
      return updated;
    });

    if (newValue.length === maxLength) {
      setIsExceeded(prev => ({ ...prev, [fieldId]: true }));
      
      if (exceededTimeouts.current[fieldId]) {
        clearTimeout(exceededTimeouts.current[fieldId]);
      }
      
      exceededTimeouts.current[fieldId] = setTimeout(() => {
        setIsExceeded(prev => ({ ...prev, [fieldId]: false }));
      }, 500);
    } else {
      setIsExceeded(prev => ({ ...prev, [fieldId]: false }));
    }
  };

  const handleGenerateLyrics = async () => {
    setIsGeneratingLyrics(true);
    try {
      const response = await fetch('/api/generate_lyrics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: WebApp.initDataUnsafe.user.id,
          prompt: formData.description
        }),
      });
      
      if (!response.ok) {
        throw new Error('Ошибка при генерации текста. Используйте другой промт.');
      }
      
      const data = await response.json();
      
      if (data.status === 'success') {
        setFormData(prev => ({
          ...prev,
          description: data.text,
          title: data.title
        }));
        localStorage.setItem(`${storagePrefix}description`, data.text);
        localStorage.setItem(`${storagePrefix}title`, data.title);
        
        if (textareaRefs.current['description']) {
          textareaRefs.current['description'].value = data.text;
        }
        if (textareaRefs.current['title']) {
          textareaRefs.current['title'].value = data.title;
        }
      } else {
        WebApp.showAlert('Ошибка при генерации текста. Используйте другой промт');
      }
    } catch (error) {
      console.error('Ошибка генерации текста:', error);
      WebApp.showAlert('Произошла ошибка при создании текста песни. Попробуйте другой промт.');
    } finally {
      setIsGeneratingLyrics(false);
    }
  };

  const handleCreateClick = useCallback(async () => {
    setIsLoading(true);
    try {
      await onSubmit(formData, extraData);
      WebApp.close();
    } catch (error) {
      console.error('Error:', error);
      WebApp.showAlert('Произошла ошибка при отправке запроса');
    } finally {
      setIsLoading(false);
    }
  }, [formData, extraData, onSubmit, setIsLoading]);

  const handleReset = () => {
    const resetData = {};
    fields.forEach(field => {
      resetData[field.id] = '';
      localStorage.removeItem(`${storagePrefix}${field.id}`);
    });
    setFormData(resetData);
    setIsExceeded({});
    if (onExtraDataChange) {
      onExtraDataChange(null);
    }
  };

  useEffect(() => {
    WebApp.MainButton
      .setText('Начать генерацию')
      .show();

    return () => {
      WebApp.MainButton.hide();
    };
  }, []);

  useEffect(() => {
    const isFormValid = fields.every(field => {
      const value = formData[field.id]?.trim() || '';
      return value !== '' && value.length <= field.maxLength;
    });
    if (isFormValid) {
      WebApp.MainButton.enable();
    } else {
      WebApp.MainButton.disable();
    }
  }, [formData, fields]);

  useEffect(() => {
    WebApp.MainButton.onClick(handleCreateClick);

    return () => {
      WebApp.MainButton.offClick(handleCreateClick);
    };
  }, [handleCreateClick]);

  useEffect(() => {
    const currentExceededTimeouts = exceededTimeouts.current;
    return () => {
      Object.values(currentExceededTimeouts).forEach(clearTimeout);
    };
  }, []);

  return (
    <div className="generation-form-container">
      {title && (
        <div className="menu-section-header">
          <h2>{title}</h2>
          <div className="menu-section-line"></div>
        </div>
      )}
      <div className="generation">
        {fields.map((field, index) => (
          <div key={field.id}>
            <div className="form-header">
              {renderHeader ? renderHeader(field, index) : (
                <>
                  <div className="label-container">
                    <label htmlFor={field.id}>{field.label}</label>
                    <Help>
                      {field.tooltip}
                    </Help>
                  </div>
                  {resetButtonLocation === 'outside' && showResetButton && index === 0 && (
                    <button className="reset-button visible" onClick={handleReset}>
                      Сбросить всё?
                    </button>
                  )}
                </>
              )}
            </div>
            <div className={`form-container ${field.containerClass}`}>
              <textarea
                ref={el => textareaRefs.current[field.id] = el}
                id={field.id}
                value={formData[field.id] || ''}
                onChange={handleInputChange(field.id, field.maxLength)}
                placeholder={field.placeholder}
                rows={field.rows}
                maxLength={field.maxLength}
              />
              <div className="form-footer">
                {field.id !== 'startTime' && (
                  <div className={`char-count ${isExceeded[field.id] ? 'exceeded' : ''}`}>
                    {(formData[field.id] || '').length} / {field.maxLength}
                  </div>
                )}
                {field.id === 'description' && (storagePrefix === 'custom_' || storagePrefix === 'audioinput_') && (
                  <button
                    className="generate-lyrics-button"
                    onClick={handleGenerateLyrics}
                    disabled={!formData.description || isGeneratingLyrics}
                  >
                    {isGeneratingLyrics ? <div className="loader"></div> : 'Сгенерировать текст'}
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
        {children}
      </div>
      {resetButtonLocation === 'inside' && showResetButton && (
        <button className="reset-button visible" onClick={handleReset}>
          Сбросить всё?
        </button>
      )}
    </div>
  );
};

export default GenerationForm;