import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import AnimatedPage from '../AnimatedPage';

import arrowIcon from '../../assets/img/arrow.svg';
import serviceIcon from '../../assets/img/8service.svg';
import voiceChangeVoiceIcon from '../../assets/img/2voice_change_voice.svg';
import textToSpeachIcon from '../../assets/img/3text_to_speech.svg';

const MenuItem = ({ icon, title, description, link }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
    WebApp.BackButton.show();
  };

  return (
    <div className="menu-item" onClick={handleClick}>
      <img src={icon} alt={title} className="menu-icon" />
      <div className="menu-text">
        <h3>{title}</h3>
      </div>
      {description && <span className="menu-description">{description}</span>}
      <img src={arrowIcon} alt="Arrow" className="arrow-icon" />
    </div>
  );
};

const Services = () => {
  const navigate = useNavigate();

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => {
      navigate('/app');
      WebApp.BackButton.hide();
    });

    return () => {
      WebApp.BackButton.offClick();
      WebApp.BackButton.hide();
    };
  }, [navigate]);

  const services = [
    {
      icon: serviceIcon,
      title: "Нейрокавер",
      description: "от 2000 ₽",
      link: `/app/services/neuro_cover`
    },
    {
      icon: voiceChangeVoiceIcon,
      title: '"Живой" нейрокавер',
      description: "от 7000 ₽",
      link: `/app/services/live_neuro_cover`
    },
    {
      icon: textToSpeachIcon,
      title: "Обучение модели",
      description: "от 10000 ₽",
      link: `/app/services/model_training`
    }
  ];

  return (
    <AnimatedPage>
      <div className="services-container">
        <div className="menu-section-header">
          <h2>УСЛУГИ</h2>
          <div className="menu-section-line"></div>
        </div>
        <div className="services-list">
          {services.map((service, index) => (
            <MenuItem key={index} {...service} />
          ))}
        </div>
        <p><b>Создадим для Вас наикрутейшие треки, которые будут радовать на протяжении всей Вашей жизни!</b></p>
        <p>Созданием и обработкой занимается, пожалуй, лучший AI-артист мира и ютубер <a href="https://www.youtube.com/@aigitarist">Ай, гитарист!"</a>, который воскресил голоса Летова, Цоя и многих других!</p>
        <p><b>1. Нейрокавер</b> - запишем трек с помощью нейросетей, обработаем и изменим на выбранный Вами голос</p>
        <p><b>2. "Живой" нейрокавер</b> - "Ай, гитарист! (Андрей)" сам споет голосами легендартных певцов. Авторское исполнение для поклонников Андрея.</p>
        <p><b>3. Обучение моделей</b> - обучим для Вас голосовую модель. Сами подготовим и обработаем датасет, проведем все корректировки и вышлем Вам готовые файлы. При желании - голос можно будет добавить в бота</p>
      </div>
    </AnimatedPage>
  );
};

export default Services;