import React from 'react';
import Lottie from 'lottie-react';

import concernedAnimation from '../../assets/js/concerned.json';

function PerformerList({ performers, onSelect }) {
  if (!Array.isArray(performers)) {
    console.error('performers is not an array:', performers);
    return <div>Error: Unable to display performers</div>;
  }

  return (
    <div className="performer-list">
      {performers.length === 0 ? (
        <>
          <div className="lottie-container-min">
            <Lottie animationData={concernedAnimation} loop={true} />
          </div>
          <p className="error-voice-search">Голосов не найдено.<br/>Проверьте настройки фильтрации</p>
        </>
      ) : (
        performers.map((performer, index) => {
          if (!performer || typeof performer !== 'object') {
            console.error('Invalid performer object:', performer);
            return null;
          }
          return (
            <button 
              key={`${performer.id}-${index}`}
              className="performer-item"
              onClick={() => onSelect(performer)}
            >
              <h3>{performer.name || 'Unnamed Performer'}</h3>
              <p>{Array.isArray(performer.tags) ? performer.tags.join(', ') : 'No tags'}</p>
            </button>
          );
        })
      )}
    </div>
  );
}

export default React.memo(PerformerList);