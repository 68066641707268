// fetch/apiProvider.js

import React, { createContext, useState, useContext, useCallback, useRef, useEffect } from 'react';
import WebApp from '@twa-dev/sdk';

const UPDATE_INTERVAL = 10000; // 10 секунд
const INITIAL_DELAY = 10000; // Задержка перед первым регулярным обновлением

const ProfileContext = createContext();

export const useProfile = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tariffs, setTariffs] = useState(null);
  const [activeTasks, setActiveTasks] = useState(null);
  const [isChannelSubscribed, setIsChannelSubscribed] = useState(null);
  const updateTimeoutRef = useRef(null);
  const isInitialLoadDone = useRef(false);
  const isFetchingRef = useRef(false);

  const deleteBotMessages = useCallback(async () => {
    try {
      const response = await fetch('/api/delete_bot_messages', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(WebApp.initDataUnsafe.user.id)
      });
      if (response.ok) {
        console.log('Задача удаления сообщений запущена');
      } else {
        console.error('Failed to start message deletion task:', await response.text());
      }
    } catch (error) {
      console.error('Error starting message deletion task:', error);
    }
  }, []);

  const fetchData = useCallback(async () => {
    if (isFetchingRef.current) return;
    isFetchingRef.current = true;
    console.log('Fetching data...');
    
    try {
      const [profileResponse, tariffsResponse, activeTasksResponse, channelSubscriptionResponse] = await Promise.all([
        fetch(`/api/get_profile?user_id=${WebApp.initDataUnsafe.user.id}`),
        fetch(`/api/tariffs?user_id=${WebApp.initDataUnsafe.user.id}`),
        fetch(`/api/check_active_tasks?user_id=${WebApp.initDataUnsafe.user.id}`),
        fetch(`/api/check_channel_subscription?user_id=${WebApp.initDataUnsafe.user.id}`)
      ]);

      if (profileResponse.ok) {
        const profileData = await profileResponse.json();
        setProfile(profileData);
        console.log('Данные профиля:', profileData);
      }

      if (tariffsResponse.ok) {
        const tariffsData = await tariffsResponse.json();
        setTariffs(tariffsData);
        console.log('Данные тарифов:', tariffsData);
      }

      if (activeTasksResponse.ok) {
        const activeTasksData = await activeTasksResponse.json();
        setActiveTasks(activeTasksData);
        console.log('Данные активных задач:', activeTasksData);
      }

      if (channelSubscriptionResponse.ok) {
        const channelSubscriptionData = await channelSubscriptionResponse.json();
        setIsChannelSubscribed(channelSubscriptionData.is_subscribed);
        console.log('Статус подписки на канал:', channelSubscriptionData);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
      isFetchingRef.current = false;
      console.log('Data fetch completed');
    }
  }, []);

  const scheduleNextUpdate = useCallback(() => {
    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }
    updateTimeoutRef.current = setTimeout(() => {
      console.log('Scheduled update triggered');
      fetchData().then(() => scheduleNextUpdate());
    }, UPDATE_INTERVAL);
  }, [fetchData]);

  useEffect(() => {
    const initialFetch = async () => {
      if (!isInitialLoadDone.current) {
        await fetchData();
        isInitialLoadDone.current = true;
        await deleteBotMessages();
        
        // Задержка перед началом регулярных обновлений
        setTimeout(scheduleNextUpdate, INITIAL_DELAY);
      }
    };

    initialFetch();

    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
        console.log('Update timeout cleared');
      }
    };
  }, [fetchData, deleteBotMessages, scheduleNextUpdate]);

  const upgradeTariff = async (newTariff) => {
    try {
      const response = await fetch('/api/upgrade_tariff', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: WebApp.initDataUnsafe.user.id, new_tariff: newTariff })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error:', error);
      WebApp.showAlert('Произошла ошибка при обработке запроса. Пожалуйста, попробуйте позже.');
      return null;
    }
  };

  // Добавляем функцию checkActiveTasks в контекст
  const checkActiveTasks = useCallback(() => {
    return activeTasks;
  }, [activeTasks]);

  return (
    <ProfileContext.Provider value={{ 
      profile, 
      tariffs, 
      activeTasks,
      isChannelSubscribed,
      isLoading, 
      upgradeTariff, 
      fetchData,
      checkActiveTasks // Добавлено
    }}>
      {children}
    </ProfileContext.Provider>
  );
};
