import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';

import GenerationForm from './GenerationForm';
import DocsLink from '../docs/DocsLink';
import AnimatedPage from '../AnimatedPage';

const CustomGeneration = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = () => {
      navigate('/app');
      WebApp.BackButton.hide();
    };

    WebApp.BackButton.show();
    WebApp.BackButton.onClick(handleBackButton);

    return () => {
      WebApp.BackButton.offClick(handleBackButton);
    };
  }, [navigate]);

  const fields = [
    {
      id: 'description',
      label: 'Текст песни',
      maxLength: 3000,
      containerClass: 'description-container',
      placeholder: 'Введите текст песни или опишите, про что хотите текст и нажмите кнопку "Сгенерировать текст"',
      tooltip: (
        <>
          Введите в этом поле текст песни<br/><br/>
          Или введите описание песни и нажмите "Сгенерировать текст". После генерации он заполнит форму.<br/><br/>
          Не указывайте здесь стили и жанры трека! Здесь необходимо указывать только текст песни, а также теги разметки<br/><br/>
          <DocsLink href="https://music.bloom-digital.ru/tegi-i-razmetka-treka-v-suno/">Подробнее про разметку</DocsLink>
        </>
      ),
      rows: 14
    },
    {
      id: 'style',
      label: 'Стили и жанры',
      maxLength: 120,
      containerClass: 'style-container',
      placeholder: 'Введите стили и жанры песни',
      tooltip: (
        <>
          Если хотите указать несколько - указывайте через запятую<br/><br/>
          <DocsLink href="https://music.bloom-digital.ru/spisok-zhanrov-suno/">Подробнее про жанры и стили</DocsLink>
        </>
      ),
      rows: 2
    },
    {
      id: 'title',
      label: 'Название',
      maxLength: 80,
      containerClass: 'title-container',
      placeholder: 'Введите название песни',
      tooltip: 'Укажите название песни, которое Вы хотите видеть после генерации',
      rows: 1
    }
  ];

  const handleSubmit = async (formData) => {
    try {
      const response = await fetch('/api/start_custom_generation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: WebApp.initDataUnsafe.user.id,
          description: formData.description,
          style: formData.style,
          title: formData.title
        }),
      });
      
      const data = await response.json();
      
      if (data.status !== 'success') {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      WebApp.showAlert(error.message);
    }
  };

  return (
    <AnimatedPage>
        <GenerationForm
          fields={fields}
          onSubmit={handleSubmit}
          showResetButton={true}
          resetButtonLocation="outside"
          storagePrefix="custom_"
          title="Кастомная генерация"
        />
    </AnimatedPage>
  );
};

export default CustomGeneration;