// Tasks.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import AnimatedPage from './AnimatedPage';
import { useProfile } from './fetch/apiProvider';

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const navigate = useNavigate();
  const { checkActiveTasks } = useProfile(); // Теперь checkActiveTasks доступна

  const getRandomInitialProgress = useCallback(() => Math.floor(Math.random() * (25 - 3 + 1) + 3), []);

  const initializeTask = useCallback((task) => {
    const storedTasks = JSON.parse(localStorage.getItem('tasks') || '{}');
    if (storedTasks[task.task_id]) {
      return storedTasks[task.task_id];
    }
    const newTask = {
      ...task,
      initialProgress: getRandomInitialProgress(),
      startTime: Date.now(),
      completed: false
    };
    storedTasks[task.task_id] = newTask;
    localStorage.setItem('tasks', JSON.stringify(storedTasks));
    return newTask;
  }, [getRandomInitialProgress]);

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => {
      navigate('/app');
      WebApp.BackButton.hide();
    });

    const fetchTasks = async () => {
      try {
        const result = await checkActiveTasks(); // Теперь вызываем доступную функцию
        if (result && result.tasks) {
          const storedTasks = JSON.parse(localStorage.getItem('tasks') || '{}');
          const newTasks = result.tasks.map(initializeTask);
          const completedTasks = Object.values(storedTasks)
            .filter(task => !newTasks.some(newTask => newTask.task_id === task.task_id) && !task.completed)
            .map(task => ({...task, completed: true}));
          
          setTasks([...newTasks, ...completedTasks]);

          // Очистка локального хранилища от неактуальных задач
          const activeTasks = newTasks.reduce((acc, task) => {
            acc[task.task_id] = storedTasks[task.task_id] || task;
            return acc;
          }, {});
          localStorage.setItem('tasks', JSON.stringify(activeTasks));
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
    const interval = setInterval(fetchTasks, 5000);

    return () => {
      clearInterval(interval);
      WebApp.BackButton.offClick();
      WebApp.BackButton.hide();
    };
  }, [navigate, checkActiveTasks, initializeTask]);

  const calculateProgress = useCallback((task) => {
    if (task.completed) return 100;
    const elapsedTime = (Date.now() - task.startTime) / 1000; // в секундах
    const progressPerSecond = (99 - task.initialProgress) / 300; // 99% за 5 минут
    return Math.min(99, task.initialProgress + elapsedTime * progressPerSecond);
  }, []);

  const getTaskTypeName = useCallback((taskType) => {
    switch(taskType) {
      case 'simple': return 'Простая генерация';
      case 'custom': return 'Кастомная генерация';
      case 'audioinput': return 'Генерация с аудиовходом';
      case 'replace_voice': return 'Замена голоса';
      case 'text_to_speech': return 'Текст в речь';
      case 'vocal_remover': return 'Вокальный разделитель';
      case 'stem_separator': return 'Разделитель стемов';
      default: return taskType;
    }
  }, []);

  useEffect(() => {
    const updateProgress = () => {
      setTasks(prevTasks => {
        const updatedTasks = prevTasks.map(task => ({
          ...task,
          progress: calculateProgress(task)
        }));
        return updatedTasks;
      });
    };

    const progressInterval = setInterval(updateProgress, 1000);

    return () => clearInterval(progressInterval);
  }, [calculateProgress]);

  return (
    <AnimatedPage>
      <div className="tasks-container">
        <div className="menu-section-header">
          <h2>Активные задачи</h2>
          <div className="menu-section-line"></div>
        </div>
        {tasks.length === 0 ? (
          <p>У вас нет активных задач</p>
        ) : (
          tasks.map(task => {
            const progress = calculateProgress(task);
            return (
              <div key={task.task_id} className="task-item">
                <h3>{getTaskTypeName(task.task_type)}</h3>
                <div className="progress-bar">
                  <div 
                    className="progress" 
                    style={{width: `${progress}%`}}
                  ></div>
                </div>
                <p>
                  {task.completed 
                    ? "Завершено. Аудио отправлено в Telegram." 
                    : `Прогресс: ${Math.round(progress)}%`
                  }
                </p>
              </div>
            );
          })
        )}
      </div>
    </AnimatedPage>
  );
};

export default Tasks;
