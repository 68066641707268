import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import { useProfile } from './fetch/apiProvider';
import { useLoader } from './other/LoaderContext';
import UserIcon from './other/UserIcon';
import GenerationIcon from './other/GenerationIcon';
import ImportantNotice from './other/ImportantNotice';
import AnimatedPage from './AnimatedPage';

import premiumPlusSvg from '../assets/img/premium_plus.svg';
import countGenerationSvg from '../assets/img/count_generation.svg';
import subEnableSvg from '../assets/img/sub_enable.svg';
import subDisableSvg from '../assets/img/sub_disable.svg';
import arrowIcon from '../assets/img/arrow.svg';

const Profile = () => {
  const { profile, tariffs, isLoading } = useProfile();
  const { setIsLoading } = useLoader();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => {
      navigate('/app');
      WebApp.BackButton.hide();
    });

    return () => {
      WebApp.BackButton.offClick();
      WebApp.BackButton.hide();
    };
  }, [navigate]);

  if (!profile || !tariffs) {
    return null;
  }

  const { first_name, last_name, username, id, credit_count, tariff, subscription_end } = profile;
  const isSubscriptionActive = tariff !== 'free';
  const currentTariffData = tariffs[tariff];

  const handleTariffsClick = () => {
    navigate('/app/tariffs');
  };

  const fullName = last_name ? `${first_name} ${last_name}` : first_name;

  return (
    <AnimatedPage>
        <div className="profile-container">
          <div className="menu-section-header">
            <h2>ПРОФИЛЬ</h2>
            <div className="menu-section-line"></div>
          </div>

          <div className="profile-info">
            <div className="profile-avatar">
              <UserIcon />
            </div>
            <div className="profile-name">
              <h3>{fullName}</h3>
            </div>
            <div className="profile-details">
              {username ? (
                <>
                  <span className="profile-username">@{username}</span>
                  <span className="profile-divider"></span>
                  <span className="profile-id">ID: {id}</span>
                </>
              ) : (
                <span className="profile-id-solo">ID: {id}</span>
              )}
            </div>
          </div>

          <div className="info-blocks">
            <div className="info-block">
              <div>Осталось генераций</div>
              <GenerationIcon icon={premiumPlusSvg} count={credit_count} />
            </div>
            <div className="info-block">
              <div>Ежедневное начисление генераций</div>
              <GenerationIcon icon={countGenerationSvg} count={currentTariffData.tariff_credits} />
            </div>
          </div>

          <div className="menu-section">
            <div className="menu-item" onClick={handleTariffsClick}>
            <img src={isSubscriptionActive ? subEnableSvg : subDisableSvg} alt="Subscription status" className="menu-icon" />
            <div className="menu-text">
                <h3>{isSubscriptionActive ? 'Подписка активна' : 'Подписка неактивна'}</h3>
              </div>
              <span className="menu-description">
                {isSubscriptionActive 
                  ? `до ${new Date(subscription_end).toLocaleDateString()}` 
                  : 'К тарифам'}
              </span>
              <img src={arrowIcon} alt="Arrow" className="arrow-icon" />
            </div>
          </div>
          {isSubscriptionActive ? (
            <ImportantNotice>
              Вам ежедневно начисляются генерации. Обновление кредитов происходит раз в день в 00:00 по МСК
            </ImportantNotice>
          ) : (
            <ImportantNotice>
              В отличии от платных тарифов - генерации на бесплатном тарифе <b>не начисляются</b>. Чтобы получить больше генераций - посмотрите раздел <span className="useNavigate" onClick={() => navigate('/app/tariffs')}>Тарифов</span>
            </ImportantNotice>
          )}
        </div>
    </AnimatedPage>
  );
};

export default Profile;