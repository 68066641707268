import React from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';

import Popup from './Popup';

import lightningAnimation from '../../assets/js/lightning.json';

const CreditAlertPopup = ({ onClose }) => {
  const navigate = useNavigate();

  const handleGoToTariffs = () => {
    onClose();
    navigate('/app/tariffs');
  };

  return (
    <Popup onClose={onClose}>
      <div className="alert-popup">
        <div className="lottie-container-min">
          <Lottie animationData={lightningAnimation} loop={true} />
        </div>
        <h3>У Вас недостаточно кредитов</h3>
        <p>Повысьте Ваш тариф</p>
        <button className="go-to-button" onClick={handleGoToTariffs}>
          К тарифам
        </button>
      </div>
    </Popup>
  );
};

export default CreditAlertPopup;