import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import Lottie from 'lottie-react';
import holydayAnimation from '../assets/js/holyday.json';
import AnimatedPage from './AnimatedPage';
import { useProfile } from './fetch/apiProvider';
import { useLoader } from './other/LoaderContext';

const ThxPayment = () => {
  const navigate = useNavigate();
  const { profile, isLoading } = useProfile();
  const { setIsLoading } = useLoader();

  useEffect(() => {
    WebApp.BackButton.hide();
  }, []);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  const handleGoHome = () => {
    navigate('/app');
  };

  if (isLoading) {
    return <div className="loader-container"><div className="loader"></div></div>;
  }

  if (!profile) {
    return <div>Ошибка загрузки данных профиля</div>;
  }

  return (
    <AnimatedPage>
      <div className="menu-section-header">
        <h2>Успешная оплата</h2>
        <div className="menu-section-line"></div>
      </div>
      <div className="thx-payment-container">
        <div className="lottie-container">
          <Lottie animationData={holydayAnimation} loop={true} />
        </div>

        <div className="thx-payment-content">
          <h3>Тариф оплачен!</h3>
          <div className="thx-payment-content-box">
            <p><b>Ваш тариф:</b> {profile.tariff_name || 'Не определен'}</p>
            <p><b>Генераций доступно сегодня:</b> {profile.credit_count || 0}</p>
            <p><b>Срок действия:</b> до {profile.subscription_end ? new Date(profile.subscription_end).toLocaleDateString() : 'Не определено'}</p>
          </div>
          <button className="to-main" onClick={handleGoHome}>На главную</button>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default ThxPayment;