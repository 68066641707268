import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './components/App';
import ErrorBoundary from './components/fetch/ErrorBoundary';
import WebApp from '@twa-dev/sdk';
import { BrowserRouter as Router } from 'react-router-dom';

// Заглушка для WebApp
if (!window.Telegram || !window.Telegram.WebApp) {
  window.Telegram = {
    WebApp: {
      initDataUnsafe: {
        user: {
          id: 12345, // тестовый ID пользователя
          first_name: "Test",
          last_name: "User",
          username: "testuser",
          language_code: "en"
        },
        auth_date: Math.floor(Date.now() / 1000),
        hash: "test_hash"
      },
      ready: () => console.log("WebApp ready called"),
      close: () => console.log("WebApp close called"),
      isWebView: true
    }
  };
  
  WebApp.initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
  WebApp.ready = window.Telegram.WebApp.ready;
  WebApp.close = window.Telegram.WebApp.close;
}

WebApp.ready();

if (WebApp.isWebView) {
  document.body.classList.add('telegram-font');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Router>
        <App />
      </Router>
    </ErrorBoundary>
  </React.StrictMode>
);