import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';

import filterIcon from '../../assets/img/filter.svg';

import SearchBar from './SearchBar';
import Tag from './Tag';
import PerformerList from './PerformerList';
import Loader from '../other/Loader';
import Help from '../other/Help';
import AnimatedPage from '../AnimatedPage';

function SearchVoice() {
  const [performers, setPerformers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTagPopup, setShowTagPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => {
      navigate('/app');
    });

    return () => {
      WebApp.BackButton.offClick();
      WebApp.BackButton.hide();
    };
  }, [navigate]);

  const fetchPerformers = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      let url = `/api/search?query=${searchQuery}`;
      if (selectedTags.length > 0) {
        url += `&tags=${selectedTags.join(',')}`;
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      let data = await response.json();
      if (!Array.isArray(data)) {
        throw new Error('Received data is not an array');
      }
      
      const uniquePerformers = data.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      
      setPerformers(uniquePerformers);
    } catch (error) {
      setError('Failed to load performers. Please try again.');
      setPerformers([]);
    } finally {
      setIsLoading(false);
    }
  }, [searchQuery, selectedTags]);

  useEffect(() => {
    fetchPerformers();
  }, [fetchPerformers]);

  const handlePerformerSelect = useCallback(async (performer) => {
    console.log('handlePerformerSelect called with performer:', performer);
    
    WebApp.showConfirm(
      `Вы выбрали голос "${performer.name}". Продолжить?`,
      async (confirmed) => {
        console.log('User confirmation:', confirmed);
        if (!confirmed) return;
      
        try {
          const searchParams = new URLSearchParams(location.search);
          const action = searchParams.get('action');
          console.log('Action:', action);
          
          const requestBody = {
            user_id: WebApp.initDataUnsafe.user.id,
            action: action,
            model_id: performer.id
          };
          console.log('Request body:', requestBody);
      
          const response = await fetch('/api/start_zamena_golosa', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
          });
      
          console.log('Response status:', response.status);
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const result = await response.json();
          console.log('Response result:', result);
      
          if (result.status === 'success') {
            WebApp.close();
          } else {
            throw new Error(result.message || 'Unknown error occurred');
          }
        } catch (error) {
          console.error('Error in handlePerformerSelect:', error);
          WebApp.showAlert('An error occurred. Please try again.');
        }
      }
    );
  }, [location.search]);

  return (
    <div className="SearchVoice">
      <AnimatedPage>
        <div className="menu-section-header">
          <h2>Выбор голоса</h2>
          <Help>Пожалуйста, выберите голос, который вы хотите использовать</Help>
          <div className="menu-section-line"></div>
        </div>
        <SearchBar 
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onSearch={fetchPerformers}
        />
        <div className="performer-list-container">
          {isLoading && <Loader />}
          {error && <div className="error-message">{error}</div>}
          {!isLoading && !error && <PerformerList performers={performers} onSelect={handlePerformerSelect} />}
        </div>
      </AnimatedPage>
      {!showTagPopup && (
        <button className="filter-button" onClick={() => setShowTagPopup(true)}>
          <img src={filterIcon} alt="Filter" />
        </button>
      )}
      {showTagPopup && (
        <Tag 
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          onClose={() => setShowTagPopup(false)}
        />
      )}
    </div>
  );
}

export default SearchVoice;