import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';

import GenerationIcon from './other/GenerationIcon';
import ImportantNotice from './other/ImportantNotice';
import { useLoader } from './other/LoaderContext';
import { useProfile } from './fetch/apiProvider';
import AnimatedPage from './AnimatedPage';

import baseSvg from '../assets/img/base.svg';
import premiumSvg from '../assets/img/premium.svg';
import premiumPlusSvg from '../assets/img/premium_plus.svg';

const TariffCard = ({ name, price, tariff_credits, isCurrent, icon, onUpgrade, upgradePrice, tariffKey, userTariff }) => {
  const showUpgradeButton = () => {
    if (userTariff === 'free') return tariffKey !== 'free';
    if (userTariff === 'base') return ['premium', 'premiumplus'].includes(tariffKey);
    if (userTariff === 'premium') return tariffKey === 'premiumplus';
    return false;
  };

  const getButtonText = () => {
    const action = userTariff === 'free' ? 'Подключить' : 'Перейти';
    const buttonPrice = upgradePrice !== undefined ? upgradePrice : price;
    return `${action} (${buttonPrice} ₽)`;
  };

  return (
    <div className="tariff-card">
      <div className="tarif-card-info">
        <h2>{name}</h2>
        <p className="price">₽ {price} / в мес.</p>
      </div>
      <div className="generations">
        <GenerationIcon icon={icon} count={tariff_credits} />
        <span className="generation-text">
          генераций <br/>в день
        </span>
      </div>
      {isCurrent ? (
        <div className="current-tariff">
          Ваш текущий тариф
        </div>
      ) : showUpgradeButton() && (
        <button className="connect-button" onClick={onUpgrade}>
          {getButtonText()}
        </button>
      )}
    </div>
  );
};

const Tariffs = () => {
  const { tariffs, isLoading, upgradeTariff } = useProfile();
  const { setIsLoading } = useLoader();
  const navigate = useNavigate();
  const [userTariff, setUserTariff] = useState('free');

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => {
      navigate('/app');
      WebApp.BackButton.hide();
    });

    return () => {
      WebApp.BackButton.offClick();
      WebApp.BackButton.hide();
    };
  }, [navigate]);

  useEffect(() => {
    setIsLoading(isLoading);
    if (tariffs) {
      const currentTariff = Object.keys(tariffs).find(key => tariffs[key].is_current) || 'free';
      setUserTariff(currentTariff);
    }
  }, [isLoading, setIsLoading, tariffs]);

  const handleUpgrade = async (tariff) => {
    setIsLoading(true);
    const data = await upgradeTariff(tariff);
    if (data && data.status === 'success') {
      navigate(`/app/payment?${data.payment_url.split('?')[1]}`);
    } else {
      WebApp.showAlert(data?.message || "Произошла ошибка при обработке запроса");
      setIsLoading(false);
    }
  };

  if (!tariffs) return null;

  const tariffIcons = {
    base: baseSvg,
    premium: premiumSvg,
    premiumplus: premiumPlusSvg
  };

  const sortedTariffs = Object.entries(tariffs)
    .filter(([key]) => key !== 'free')
    .sort((a, b) => a[1].price - b[1].price);

  return (
    <AnimatedPage>
      <div className="tariffs-container">
        <div className="menu-section-header">
          <h2>Тарифы</h2>
          <div className="menu-section-line"></div>
        </div>
        <div className="tariffs-flex">
          {sortedTariffs.map(([key, tariff]) => (
            <TariffCard
              key={key}
              tariffKey={key}
              name={tariff.name}
              price={tariff.price}
              tariff_credits={tariff.tariff_credits}
              isCurrent={tariff.is_current}
              icon={tariffIcons[key]}
              onUpgrade={() => handleUpgrade(key)}
              upgradePrice={tariff.upgrade_price}
              userTariff={userTariff}
            />
          ))}
        </div>
        {userTariff !== 'free' && (
          <ImportantNotice>
            Вы можете доплатить и повысить тариф в любой момент. Дата окончания тарифа останется неизменной. <br/><br/>Если Вы хотите понизить тариф - Вам необходимо дождаться завершения действия подписки и перейти на новый тариф.
          </ImportantNotice>
        )}
        <div className="tariffs-information">
          <p>Весь функционал бота доступен на всех тарифах. Отличается только кол-во генераций.</p>
          <ul>
            <li><b>Срок действия тарифа:</b> 30 дней</li>
          </ul>
          <p><b>Автосписание отключено.</b> Каждый месяц в бота Вам будет приходить счёт, который необходимо оплатить в ручную.</p>
          <p>Используйте только <b>оригинальное приложение Telegram</b> для Вашей платформы. На сторонних модах работа оплаты не гарантируется</p>
          <p>Онлайн оплата доступна только <b>пользователям из РФ.</b> Если Вы хотите оплатить из другой страны - пожалуйста, свяжитесь с поддержкой <a href="https://t.me/sundio_support">@sundio_support</a></p>
          <p>На бесплатном тарифе генерации НЕ НАЧИСЛЯЮТСЯ КАЖДЫЙ ДЕНЬ - есть только бонусные генерации, которые Вам дали после регистрации. На всех остальных тарифах начисление генераций происходит ежедневно в 00:00 по МСК</p>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default Tariffs;