import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from './ThemeProvider';
import questionIcon from '../../assets/img/question.svg';

const Help = ({ children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { colorScheme } = useTheme();
  const tooltipRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showTooltip && 
          tooltipRef.current && 
          !tooltipRef.current.contains(event.target) &&
          !iconRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTooltip]);

  const handleToggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const renderContent = () => {
    if (typeof children === 'string') {
      return <div dangerouslySetInnerHTML={{ __html: children }} />;
    }
    return children;
  };

  return (
    <div className="help-container">
      <img
        ref={iconRef}
        src={questionIcon}
        alt="Help"
        className={`help-icon ${colorScheme === 'light' ? 'help-icon-invert' : ''}`}
        onClick={handleToggleTooltip}
      />
      {showTooltip && (
        <div ref={tooltipRef} className="tooltip">
          {renderContent()}
        </div>
      )}
    </div>
  );
};

export default Help;