import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Main from './Main';
import SearchVoice from './search_voice/SearchVoice';
import Payment from './Payment';
import SimpleGeneration from './generation/SimpleGeneration';
import CustomGeneration from './generation/CustomGeneration';
import AudioinputGeneration from './generation/AudioinputGeneration';
import Profile from './Profile';
import Tariffs from './Tariffs';
import Services from './services/Services';
import AllServices from './services/AllServices';
import ThxPayment from './ThxPayment';
import DocsPage from './docs/DocsPage';
import Support from './Support';
import Tasks from './Tasks';
import Banned from './Banned';

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/app" element={<Main />} />
        <Route path="/app/search_voice" element={<SearchVoice />} />
        <Route path="/app/payment" element={<Payment />} />
        <Route path="/app/simple_generation" element={<SimpleGeneration />} />
        <Route path="/app/custom_generation" element={<CustomGeneration />} />
        <Route path="/app/audioinput_generation" element={<AudioinputGeneration />} />
        <Route path="/app/profile" element={<Profile />} />
        <Route path="/app/tariffs" element={<Tariffs />} />
        <Route path="/app/services" element={<Services />} />
        <Route path="/app/services/:serviceType" element={<AllServices />} />
        <Route path="/app/thx-payment" element={<ThxPayment />} />
        <Route path="/app/browser" element={<DocsPage />} />
        <Route path="/app/support" element={<Support />} />
        <Route path="/app/tasks" element={<Tasks />} />
        <Route path="/app/banned" element={<Banned />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;