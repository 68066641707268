import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import AnimatedPage from '../AnimatedPage';
import { useLoader } from '../other/LoaderContext';

const DocsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const url = new URLSearchParams(location.search).get('url');
  const backUrl = new URLSearchParams(location.search).get('back') || '/app';
  const iframeRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const { setIsLoading } = useLoader();

  useEffect(() => {
    setIsLoading(true);
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => {
      navigate(backUrl);
    });

    const handleMessage = (event) => {
      if (event.data === 'iframeReady') {
        const computedStyles = getComputedStyle(document.documentElement);
        const bgColor = computedStyles.getPropertyValue('--tg-theme-secondary-bg-color').trim() || '#ffffff';
        const secondaryBgColor = computedStyles.getPropertyValue('--tg-theme-bg-color').trim() || '#4b4947';
        const textColor = computedStyles.getPropertyValue('--tg-theme-text-color').trim() || '#000000';
        const linkColor = computedStyles.getPropertyValue('--tg-theme-link-color').trim() || '#007bff';
        const hintColor = computedStyles.getPropertyValue('--tg-theme-hint-color').trim() || '#aaaaaa';
        const defaultFontFamily = 'system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif';
        const fontFamily = computedStyles.getPropertyValue('--tg-font').trim() || defaultFontFamily;
        
        const styles = `
          body, h1, h2, h3, h4, h5, p, ul, li {          
            font-family: ${fontFamily} !important;
            color: ${textColor} !important;
            font-size: 16px !important;
          }
          body {
            background-color: ${bgColor} !important;
          }
          body .has-x-large-font-size {
            font-size: 28px !important;
            font-weight: bold !important;
            font-family: ${fontFamily} !important;
          }
          h2 {
            font-size: 24px !important;
          }
          h3 {
            font-size: 18px !important;
          }
          h4 {
            font-size: 16px !important;
          }
          h5 {
            font-size: 14px !important;
          }
          a {
            text-decoration: none !important;
            color: ${linkColor};
          }
          p {
          }
          .wp-block-template-part, .wp-block-separator, 
          .is-content-justification-space-between, footer,
          .wp-block-query-title {
            display: none !important;
          }
          .wp-block-post {
            padding: 20px !important;
            border-radius: 13px !important;
            background-color: ${secondaryBgColor} !important;
          }
          .wp-block-post-title > a {
            font-size: 15px !important;
            line-height: 1.5 !important;
            color: ${textColor};
          }
          .wp-block-post-template-is-layout-grid {
            margin-top: -15px !important;
          }
          .rank-math-breadcrumb {
            font-family: ${fontFamily} !important;
            margin-top: -30px !important;
          }
          .rank-math-breadcrumb .last {
            color: ${hintColor} !important;
          }
          .rank-math-breadcrumb .separator {
            color: ${hintColor} !important;
          }

          ul {
            list-style-type: none !important;
            padding-left: 0 !important;
          }
          
          li {
            position: relative !important;
            padding-left: 20px !important;
            margin-bottom: 10px !important;
          }
          
          li::before {
            content: '' !important;
            position: absolute !important;
            left: 0 !important;
            top: 0.7em !important;
            transform: translateY(-50%) !important;
            width: 10px !important;
            height: 1px !important;
            background-color: ${hintColor} !important;
          }
          .wp-block-quote, .wp-block-code {
            font-family: ${fontFamily} !important;
            background-color: ${secondaryBgColor} !important;
            color: ${textColor} !important;
            font-size: 14px !important;
          }
        `;

        iframeRef.current.contentWindow.postMessage({ type: 'applyStyles', styles }, '*');
        setIframeLoaded(true);
        setIsLoading(false);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      WebApp.BackButton.offClick();
      WebApp.BackButton.hide();
      window.removeEventListener('message', handleMessage);
      setIsLoading(false);
    };
  }, [navigate, url, setIsLoading, backUrl]);

  if (!url) {
    return <div>Error: No URL provided</div>;
  }

  return (
    <AnimatedPage>
      <div className="inapp-browser-container" style={{ margin: '0 -20px' }}>
        <iframe 
          ref={iframeRef}
          src={url}
          style={{
            width: '100%',
            height: 'calc(100vh - 60px)',
            border: 'none',
            display: iframeLoaded ? 'block' : 'none',
          }}
          title="In-App Browser"
        />
      </div>
    </AnimatedPage>
  );
};

export default DocsPage;