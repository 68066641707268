import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import GenerationIcon from './other/GenerationIcon';
import UserIcon from './other/UserIcon';
import logoIcon from '../assets/img/logo.svg';
import { useThemeClass } from './other/ThemeProvider';

const Header = () => {
  const navigate = useNavigate();
  const logoClass = useThemeClass('app-logo');

  const handleGenerationIconClick = () => {
    navigate('/app/tariffs');
  };

  const handleUserIconClick = () => {
    navigate('/app/profile');
  };

  return (
    <header className="app-header">
      <Link to="/app" className={logoClass}>
        <img src={logoIcon} alt="Logo" />
      </Link>
      <div className="app-icons">
        <div onClick={handleGenerationIconClick} style={{ cursor: 'pointer' }}>
          <GenerationIcon />
        </div>
        <div onClick={handleUserIconClick} style={{ cursor: 'pointer' }}>
          <UserIcon />
        </div>
      </div>
    </header>
  );
};

export default Header;
