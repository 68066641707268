import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import ImportantNotice from './other/ImportantNotice';
import GenerationIcon from './other/GenerationIcon';
import Help from './other/Help';
import { useProfile } from './fetch/apiProvider';
import AnimatedPage from './AnimatedPage';
import CreditAlertPopup from './popups/CreditAlertPopup';
import ActiveTasksAlertPopup from './popups/ActiveTasksAlertPopup';
import ChannelSubscriptionChecker from './popups/ChannelSubscriptionChecker';
import DocsLink from './docs/DocsLink';

import tasksIcon from '../assets/img/tasks.svg';
import arrowIcon from '../assets/img/arrow.svg';
import simpleGenerationIcon from '../assets/img/SimpleGeneration.svg';
import customGenerationIcon from '../assets/img/CustomGeneration.svg';
import audioGenerationIcon from '../assets/img/AudioGeneration.svg';
import voiceChangeTrackIcon from '../assets/img/1voice_change_track.svg';
import textToSpeachIcon from '../assets/img/3text_to_speech.svg';
import addVoiceIcon from '../assets/img/4add_voice.svg';
import vocalSeparationIcon from '../assets/img/5vocal_separation.svg';
import stemSeparationIcon from '../assets/img/stem_separation.svg';
import profileIcon from '../assets/img/6profile.svg';
import tariffsIcon from '../assets/img/7tariffs.svg';
import serviceIcon from '../assets/img/8service.svg';
import docsIcon from '../assets/img/9docs.svg';
import supportIcon from '../assets/img/10support.svg';

const MenuItem = ({ icon, title, description, link, handleItemClick }) => {
  return (
    <div className="menu-item" onClick={() => handleItemClick(link)}>
      <img src={icon} alt={title} className="menu-icon" />
      <div className="menu-text">
        <h3>{title}</h3>
      </div>
      {description && <span className="menu-description">{description}</span>}
      <img src={arrowIcon} alt="Arrow" className="arrow-icon" />
    </div>
  );
};

const MenuSection = ({ title, items, handleItemClick, helpText }) => (
  <div className="menu-section">
    <div className="menu-section-header">
      <h2>{title}</h2>
      {helpText && <Help>{helpText}</Help>}
      <div className="menu-section-line"></div>
    </div>
    {items.map((item, index) => (
      <MenuItem key={index} {...item} handleItemClick={handleItemClick} />
    ))}
  </div>
);

const Main = () => {
  const [showNotice, setShowNotice] = useState(true);
  const [showCreditAlert, setShowCreditAlert] = useState(false);
  const [showActiveTasksAlert, setShowActiveTasksAlert] = useState(false);
  const [showChannelSubscriptionChecker, setShowChannelSubscriptionChecker] = useState(false);
  const { profile, activeTasks, isChannelSubscribed, fetchData } = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    const noticeShown = sessionStorage.getItem('noticeShown');
    if (noticeShown) {
      setShowNotice(false);
    }

    WebApp.BackButton.hide();

    // Выполняем начальную загрузку данных
    fetchData();

    return () => {
      WebApp.BackButton.offClick();
    };
  }, [fetchData]);

  const handleCloseNotice = () => {
    setShowNotice(false);
    sessionStorage.setItem('noticeShown', 'true');
  };

  const handleItemClick = async (link) => {
    if ([
      '/app/simple_generation',
      '/app/custom_generation',
      '/app/audioinput_generation',
      '/app/search_voice?action=replace_voice',
      '/app/search_voice?action=text_to_speech',
      '/app/vocal_separator',
      '/app/stem_separator'
    ].includes(link)) {
      // Показываем попап подписки на канал только платным подписчикам
      if (profile?.tariff !== 'free' && !isChannelSubscribed) {
        setShowChannelSubscriptionChecker(true);
        return;
      }
    
      if (profile?.credit_count === 0) {
        setShowCreditAlert(true);
        return;
      }
      
      if (activeTasks && !activeTasks.can_start_new_task) {
        setShowActiveTasksAlert(true);
        return;
      }
    }
  
    if (link === '/app/audioinput_generation') {
      WebApp.showConfirm(
        "Приложение сейчас закроется. Отправьте аудио в чат.",
        (confirmed) => {
          if (confirmed) {
            audioInputGeneration();
          }
        }
      );
    } else if (link === '/app/vocal_separator') {
      try {
        const response = await fetch('/api/start_vocal_remover', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: WebApp.initDataUnsafe.user.id
          }),
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Неизвестная ошибка');
        }
    
        const data = await response.json();
        if (data.status === 'success') {
          WebApp.close();
        } else {
          throw new Error(data.message || 'Неизвестная ошибка');
        }
      } catch (error) {
        console.error('Ошибка:', error);
        WebApp.showAlert(`Произошла ошибка: ${error.message}`);
      }
    } else if (link === '/app/stem_separator') {
      try {
        const response = await fetch('/api/start_stem_separation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: WebApp.initDataUnsafe.user.id
          }),
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Неизвестная ошибка');
        }
    
        const data = await response.json();
        if (data.status === 'success') {
          WebApp.close();
        } else {
          throw new Error(data.message || 'Неизвестная ошибка');
        }
      } catch (error) {
        console.error('Ошибка:', error);
        WebApp.showAlert(`Произошла ошибка: ${error.message}`);
      }
    } else if (link.startsWith('http')) {
      window.open(link, '_blank');
    } else {
      navigate(link);
    }
  };
  
  const audioInputGeneration = async () => {
    try {
      const response = await fetch('/api/audio_input_message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: WebApp.initDataUnsafe.user.id
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Неизвестная ошибка');
      }
  
      const data = await response.json();
      if (data.status === 'success') {
        WebApp.close();
      } else {
        throw new Error(data.message || 'Неизвестная ошибка');
      }
    } catch (error) {
      console.error('Ошибка:', error);
      WebApp.showAlert(`Произошла ошибка: ${error.message}`);
    }
  };
  
  const menuSections = [
    {
      title: 'ГЕНЕРАЦИЯ МУЗЫКИ',
      helpText: (
        <>
          <b>Простая генерация</b> подходит для новичков - она сама создаст текст трека по Вашему описанию<br/><br/>
          <b>Кастомная генерация</b> позволяет загружать свой текст песни и указывать стили трека<br/><br/>
          <b>Генерация со своим аудио</b> даёт возможность использовать собственный бит для создания трека<br/><br/>
          <DocsLink href="https://music.bloom-digital.ru/how-use-suno-bot/">Как использовать бота для создания треков?</DocsLink>
        </>
      ),
      items: [
        {
          icon: simpleGenerationIcon,
          title: 'Простая генерация',
          description: 'Создаст текст',
          link: '/app/simple_generation'
        },
        {
          icon: customGenerationIcon,
          title: 'Кастомная генерация',
          description: 'Свой текст',
          link: '/app/custom_generation'
        },
        {
          icon: audioGenerationIcon,
          title: 'Генерация со своим аудио',
          description: 'Свой бит',
          link: '/app/audioinput_generation'
        }
      ]
    },
    {
      title: 'ЗАМЕНА ГОЛОСА',
      helpText: (
        <>
          <b>Замена голоса</b> позволяет изменять голос в песне или записи речи на голоса персонажей и знаменитостей из нашей библиотеки. Можно использовать как с треками, так и с голосовыми сообщениями.<br/><br/>
          <b>Текст в речь</b> читает отправленный текст выбранным голосом<br/><br/>
          <DocsLink href="https://music.bloom-digital.ru/kak-izmenyat-golos-i-delat-nejrokavery-baza-dlya-novichkov/">Гайд по замене голоса</DocsLink>
        </>
      ),
      items: [
        {
          icon: voiceChangeTrackIcon,
          title: 'Замена голоса',
          link: '/app/search_voice?action=replace_voice'
        },
        {
          icon: textToSpeachIcon,
          title: 'Текст в речь',
          description: 'Beta',
          link: '/app/search_voice?action=text_to_speech'
        },
        {
          icon: addVoiceIcon,
          title: 'Добавить новый голос',
          description: 'Заявка',
          link: '/app/services/new_voice'
        }
      ]
    },
    {
      title: 'ИНСТРУМЕНТЫ',
      helpText: (
        <>
          <b>Вокальный разделитель</b> отделяет вокал от инструментала<br/><br/>
          <b>Разделитель стемов</b> делит трек на вокал, басс, барабаны и другие инструменты
        </>
      ),
      items: [
        {
          icon: vocalSeparationIcon,
          title: 'Вокальный разделитель',
          link: '/app/vocal_separator'
        },
        {
          icon: stemSeparationIcon,
          title: 'Разделитель стемов',
          link: '/app/stem_separator'
        }
      ]
    },
    {
      title: 'ИНФОРМАЦИЯ',
      items: [
        {
          icon: profileIcon,
          title: 'Профиль',
          link: '/app/profile'
        },
        {
          icon: tariffsIcon,
          title: 'Тарифы',
          description: profile ? profile.tariff_name : 'Не определен',
          link: '/app/tariffs'
        },
        {
          icon: serviceIcon,
          title: 'Услуги',
          link: '/app/services/'
        },
        {
          icon: docsIcon,
          title: 'Инструкции',
          link: '/app/browser?url=https://music.bloom-digital.ru/category/docs/'
        },
        {
          icon: supportIcon,
          title: 'Поддержка',
          link: '/app/support'
        }
      ]
    }
  ];

  return (
    <>
      <AnimatedPage>
          <div className="main-container">
            <div className="info-blocks">
              <div className="info-block" onClick={() => handleItemClick('/app/tariffs')}>
                <div>Осталось генераций</div>
                <GenerationIcon count={profile?.credit_count} />
              </div>
              <div className="info-block" onClick={() => navigate('/app/tasks')}>
                <div>Активные задачи</div>
                <img src={tasksIcon} alt="Tasks" className="tg-icon" />
              </div>
            </div>
            {showNotice && profile && profile.tariff === 'free' && (
              <ImportantNotice onClose={handleCloseNotice}>
                Прежде чем начать, ознакомьтесь с <DocsLink href="https://music.bloom-digital.ru/category/docs/">инструкциями</DocsLink> для более качественной генерации
              </ImportantNotice>
            )}
            {menuSections.map((section, index) => (
              <MenuSection key={index} {...section} handleItemClick={handleItemClick} />
            ))}
          </div>
      </AnimatedPage>
      {showCreditAlert && (
        <CreditAlertPopup onClose={() => setShowCreditAlert(false)} />
      )}
      {showActiveTasksAlert && activeTasks && (
        <ActiveTasksAlertPopup 
          onClose={() => setShowActiveTasksAlert(false)}
          activeTasks={activeTasks.active_tasks}
          maxTasks={activeTasks.max_tasks}
        />
      )}
      {showChannelSubscriptionChecker && (
        <ChannelSubscriptionChecker onClose={() => setShowChannelSubscriptionChecker(false)} />
      )}
    </>
  );
};

export default Main;