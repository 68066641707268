// src/components/Maintenance.js
import React from 'react';
import AnimatedPage from './AnimatedPage';
import Lottie from 'lottie-react';
import sleepingAnimation from '../assets/js/sleeping.json';

const Maintenance = () => {
  return (
    <AnimatedPage>
      <div className="maintenance-container">
        <div className="lottie-container-min">
          <Lottie animationData={sleepingAnimation} loop={true} />
        </div>
        <h3>Мы прилегли поспать</h3>
        <p>
          Уже всё чиним! Приходите чуть позже...
        </p>
      </div>
    </AnimatedPage>
  );
};

export default Maintenance;