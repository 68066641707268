import React from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import Popup from './Popup';
import thundergAnimation from '../../assets/js/thunder.json';

const ActiveTasksAlertPopup = ({ onClose, activeTasks, maxTasks }) => {
  const navigate = useNavigate();

  const handleGoToTasks = () => {
    onClose();
    navigate('/app/tasks');
  };

  return (
    <Popup onClose={onClose}>
      <div className="alert-popup">
        <div className="lottie-container-min">
          <Lottie animationData={thundergAnimation} loop={true} />
        </div>
        <h3>У Вас уже есть активные задачи</h3>
        <p>
          У Вас уже есть {activeTasks} активные задачи. <br/>Ожидайте их завершения.
        </p>
        <button className="go-to-button" onClick={handleGoToTasks}>
          К задачам
        </button>
      </div>
    </Popup>
  );
};

export default ActiveTasksAlertPopup;